
import { BBox, TileProvider, TileRef } from "../types";
import { Point, Map, LatLng, Canvas } from "leaflet";
import { GeoUtils } from "./GeoUtils";

export * from "./GeoUtils";
export * from "./ColorUtils";
export * from "./LeafletUtils";

export const getCenterOfArea = (area:LatLng[]):LatLng => {
	const lat = area.map(p => p.lat).sort();
	const lng = area.map(p => p.lng).sort();
	const lats = Math.abs(lat[1] - lat[0]);
	const lngs = Math.abs(lng[1] - lng[0]);
	return new LatLng(lat[0] + (lats * 0.5), lng[0] - (lngs * 0.5));
};

export const getCoordinatesFromBounds = (bb:BBox, map:Map) => {
	return [
		{ x:bb.x, y:bb.y },
		{ x:bb.x + bb.w, y:bb.y + bb.h },
	]
	.map(p => new Point(p.x, p.y))
	.map(p => map.containerPointToLatLng(p));
};

export const getBoundsFromCoordinates = (coordinates:LatLng[], map:Map) => {
	const p1 = map.latLngToContainerPoint(coordinates[0]);
	const p2 = map.latLngToContainerPoint(coordinates[1]);
	return {
		...p1,
		w:Math.abs(p1.x - p2.x),
		h:Math.abs(p1.y - p2.y),
	};
};

export const copyContent = (c:any, input?:HTMLInputElement) => {
	if(c === undefined || c === null){ return; }
	const txt = JSON.stringify(c, null, "\t");
	if(input){
		input.value = txt;
		input.select();
		document.execCommand("copy");
	}
	else {
		navigator.clipboard.writeText(txt);
	}
};

export const getAreaExtents = (area:LatLng[]) => {
	return {
		lat:[
			Math.min(area[0].lat, area[1].lat),
			Math.max(area[0].lat, area[1].lat),
		],
		lng:[
			Math.min(area[0].lng, area[1].lng),
			Math.max(area[0].lng, area[1].lng),
		]
	};
};

export const getImageFromArea = async (area:LatLng[], zoom:number, tilePath:string) => {
	const {
		tiles,
		clip,
	} = getAreaTiles(area, zoom);
	const urls = tileGridToURLs(tiles, tilePath);
	const imgs = await loadImages(urls);

	const { width:iw, height:ih } = imgs[0][0];

	const height = imgs.length * ih;;
	const width = imgs[0].length * iw;

	const cropl = clip.x[0] * iw;
	const cropt = clip.y[0] * ih;
	const cropr = (1 - clip.x[1]) * iw;
	const cropb = (1 - clip.y[1]) * ih;

	const cropw = width - (cropl + cropr);
	const croph = height - (cropt + cropb);

	const canvas = await gridToCanvas(imgs);
	const cropped = cropCanvas(canvas, cropl, cropt, cropw, croph);

	const name = [
		zoom,
		area.map(p => `${p.lat},${p.lng}`).join(",")
	].join(",");

	downloadCanvas(cropped, name);
};

const cropCanvas = (canvas:HTMLCanvasElement, x:number, y:number, w:number, h:number) => {
	
	

	// ctx.beginPath();
	// ctx.rect(x, y, w, h);
	// ctx.fillStyle = "black";
	// ctx.fill();
	
	// ctx.drawImage(canvas, x, y, w, h);


	const ccanvas = document.createElement("canvas");
	ccanvas.width = w;
	ccanvas.height = h;

	const ctx = ccanvas.getContext("2d");

	if(!ctx){ throw new Error("Game over, man"); }

	ctx.drawImage(canvas, x, y, w, h, 0, 0, w, h);

	return ccanvas;

	// canvas.width = w;
	// canvas.height = h;

};


// returns a grid of image urls for coordinates
const getAreaTiles = (area:LatLng[], zoom:number) => {

	const extents = getAreaExtents(area);
	const xrange = extents.lng.map(v => GeoUtils.lngToTileIndex(v, zoom, false)).sort();
	const yrange = extents.lat.map(v => GeoUtils.latToTileIndex(v, zoom, false)).sort();
	const rcount = (Math.floor(yrange[1]) - Math.floor(yrange[0])) + 1;
	const ccount = (Math.floor(xrange[1]) - Math.floor(xrange[0])) + 1;
	const rows:TileRef[][] = [];

	for(let i = 0; i < rcount; i++){
		const row:TileRef[] = [];
		for(let j = 0; j < ccount; j++){
			row.push({
				x:Math.floor(xrange[0]) + j,
				y:Math.floor(yrange[0]) + i,
				z:zoom,
			})
		}
		rows.push(row);
	}
	return {
		tiles:rows,
		clip:{
			x:xrange.map(v => v - Math.floor(v)),
			y:yrange.map(v => v - Math.floor(v)),
		}
	};
};


const tileGridToURLs = (tiles:TileRef[][], urlTemplate:string):string[][] => {
	return tiles.map(trow => trow.map(tcol => tileToURL(tcol, urlTemplate)))
};

const tileToURL = (tile:TileRef, url:string):string => {
	Object.keys(tile)
	.forEach((k) => url = url.replace(`{${k}}`, (tile as any)[k].toString()))
	return url;
};


const gridToCanvas = async (imgs:HTMLImageElement[][]) => {

	const width = imgs[0]
	.map(img => img.width)
	.reduce((w, sum) => sum + w, 0);

	const height = imgs
	.map(row => row[0].height)
	.reduce((w, sum) => sum + w, 0);

	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;
	const ctx = canvas.getContext("2d");
	if(!ctx){
		throw new Error("Some bullshit happened");
	}
	forEachGridItem(imgs, (img, row, col) => {
		const x = col * img.width;
		const y = row * img.height;
		ctx.drawImage(img, x, y, img.width, img.height);
	});
	return canvas;
};

const downloadCanvas = (canvas:HTMLCanvasElement, name:string) => {
	const encoding = "jpeg";
	const data = canvas.toDataURL(`image/${encoding};base64;`);
	const a = document.createElement("a");
	a.href = data;
	a.download = `${name}.${encoding}`;
	a.style.cssText = "opacity:0;width:0;height:0;position:fixed;";
	document.body.appendChild(a);
	a.click();
	a.remove();
};

const forEachGridItem = <T>(grid:T[][], fn:(it:T, i:number, j:number) => void) => {
	for(let i = 0; i < grid.length; i++){
		for(let j = 0; j < grid[i].length; j++){
			fn(grid[i][j], i, j);
		}
	}
}

const flattenGridArray = <T>(grid:T[][]):T[] => {
	const arr:T[] = [];
	grid.forEach(r => r.forEach(c => arr.push(c)));
	return arr;
}

const arrayToGrid = <T>(arr:T[], cols:number) => {
	const grid:T[][] = [];
	const rows = arr.length / cols;
	for(let i = 0; i < rows; i++){
		const ci = i * cols;
		grid.push(arr.slice(ci, ci + cols));
	}
	return grid;
};

const loadImages = async(grid:string[][]) => {
	const urls = flattenGridArray(grid);
	const imgs:(HTMLImageElement|null)[] = urls.map(() => null);
	await Promise.all(urls.map((url, i) => {
		return new Promise<void>(async (res, rej) => {
			imgs[i] = await loadImage(url);
			res();
		});
	}));
	return arrayToGrid(imgs as HTMLImageElement[], grid[0].length);
};


const loadImage = async(url:string):Promise<HTMLImageElement> => {
	return new Promise((res, rej) => {
		const img = new Image();
		img.crossOrigin = "anonymous";
		img.onerror = rej;
		img.onload = () => res(img);
		img.src = url;
	});
};