import IndexPage from "@pages/index";
import RankPage from "@pages/rank";
import UtilsPage from "@pages/utils/index";
import MarkerCSVUtilsPage from "@pages/utils/import-marker-csv";

type RouteConfig = {
	path:string;
	component:any;
};

export const routes:RouteConfig[] = [
	{
		path:"/",
		component:IndexPage,
	},
	{
		path:"/rank",
		component:RankPage,
	},
	{
		path:"/utils",
		component:UtilsPage,
	},
	{
		path:"/utils/import-marker-csv",
		component:MarkerCSVUtilsPage,
	}
];