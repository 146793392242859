import { Box } from "@ui";
import { FC } from "react";
import styled from "styled-components";
import { GUID } from "../../@utils";
import { ColorUtils } from "../utils";
import { TooltipWrapper } from "./Tooltip";

export const GradientSlider:FC<{
	colors:string[];
	value:number;
	segments?:number;
	valueColor?:string;
	valueTooltip?:any;
}> = (props) => {

	const style:any = {
		height:"15px",
		width:"100%",
		position:"relative"
	};

	const valueColor = props.valueColor || "#fff";

	const segments = props.segments || 10;
	
	const colors:string[] = [];

	for(let i = 0; i < segments; i++){
		const t = (1 / segments) * i;
		colors.push(ColorUtils.lerp(props.colors[0], props.colors[1], t));
	}

	const drects = colors.map((c, i) => {
		const rw = 1 / colors.length;
		return (
			<rect
			key={ GUID.getGUID() }
			height="1" x={ rw * i } width={ rw } fill={ c }/>
		);
	});

	const arrow = (
		<TooltipWrapper text={ props.valueTooltip || "" }>
			<ValueArrow
			color={ valueColor }
			value={ props.value }
			/>
		</TooltipWrapper>
	);

	return (
		<GradientWrapper style={style}
		className=""
		>
			<Box.Abs>
				<GradientSliderCanvas viewBox="0 0 1 1" preserveAspectRatio="none">
					{ drects }
					<line
					x1={props.value}
					y1="0" x2={props.value}
					y2="1"
					stroke={valueColor}
					strokeWidth={0.005}/>
				</GradientSliderCanvas>
			</Box.Abs>
			<Box.Abs>
				{ arrow }
			</Box.Abs>
		</GradientWrapper>
	);
};



const ValueArrow = styled.div<{
	value:number;
	color:string;
	size?:number;
}>`
	width:${({ size }) => size}em;
	height:${({ size }) => size}em;
	background:${({ color }) => color};
	border:2px solid white;
	border-radius:0.3em;
	position:absolute;
	left:${({ value }) => value * 100}%;
	top:50%;
	transform-origin: 0% 0%;
	transform: rotate(45deg) translate(-50%,-50%);
`;

ValueArrow.defaultProps = {
	size:1.5
};

const GradientWrapper = styled.div`
	height:15px;
	width:100%;
	position:relative;
`;

const GradientSliderCanvas = styled.svg`
	width:100%;
	height:100%;
	background:#fff;
	top:0;
	position:absolute;

	border:1px solid white;


	border-radius: 0.25em;
`;