import { FC } from "react";
import ReactTooltip from "react-tooltip";

export const TooltipWrapper:FC<{
	text:string;
}> = (props) => {
	return (
		<span
		data-tip={ props.text }
		data-class="text-center p-1"
		>
			<ReactTooltip/>
			{ props.children }
		</span>
	);
};