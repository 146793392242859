import { FC } from "react";
import styled from "styled-components";


interface IButton3D {
	text?:string;
	h?:number;
	l?:number;
	s?:number;
	disabled?:boolean;
	onClick?:() => void;
	
};



// original: h=340, l=47

export const Button3D:FC<IButton3D> = (props) => {

	const { text } = props;


	const h = props.h !== undefined ? props.h : 200;
	const s = props.s !== undefined ? props.s : 100;
	const l = props.l !== undefined ? props.l : 50;

	return (
		<Button
		h={h}
		l={l}
		s={s}
		onClick={props.onClick}
		disabled={props.disabled}
		>
			<span className="shadow"></span>
			<span className="edge"></span>
			<span className="front d-flex">
				<span className="my-auto">
					{text}
				</span>
			</span>
		</Button>
	);
};

interface IStyledButton {
	h:number;
	s:number;
	l:number;
}

const Button = styled.button<IStyledButton>`

	position: relative;
	border: none;
	background: transparent;
	padding: 0;
	cursor: pointer;
	outline-offset: 4px;
	transition: filter 250ms;

	/* font-weight: bold; */
	font-family: Rowdies;
	text-transform: uppercase;


	&:disabled {
		pointer-events: none;
		opacity:0.3;
	}


	&:hover {
		filter: brightness(110%);
	}

	&:hover .front {
		transform: translateY(-6px);
		transition:
			transform
			250ms
			cubic-bezier(.3, .7, .4, 1.5);
	}


	&:active .front {
		transform: translateY(-2px);
		transition: transform 34ms;
	}

	&:hover .shadow {
		transform: translateY(4px);
		transition:
			transform
			250ms
			cubic-bezier(.3, .7, .4, 1.5);
	}

	&:active .shadow {
		transform: translateY(1px);
		transition: transform 34ms;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	.shadow {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.5em;
		background: hsl(0deg 0% 0% / 0.25);
		will-change: transform;
		transform: translateY(2px);
		transition:
			transform
			600ms
			cubic-bezier(.3, .7, .4, 1);
	}

	.edge {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.5em;
		background: linear-gradient(
		to left,
		hsl(${({h}) => h}deg ${({s}) => s}% 16%) 0%,
		hsl(${({h}) => h}deg ${({s}) => s}% 32%) 8%,
		hsl(${({h}) => h}deg ${({s}) => s}% 32%) 92%,
		hsl(${({h}) => h}deg ${({s}) => s}% 16%) 100%
		);
	}

	.front {
		display: block;
		position: relative;
		padding: 0.5em 0.75em;
		border-radius: 0.5em;
		font-size: 1em;
		color: white;
		background: hsl(${({h}) => h + 5}deg ${({s}) => s}% ${({l}) => l}%);
		will-change: transform;
		transform: translateY(-4px);
		transition:
		transform
		600ms
		cubic-bezier(.3, .7, .4, 1);
	}

	

`;