
export interface IMarkerConfig {
	readonly fields:Record<string,IMarkerField>;
	readonly markers:IMarkerContent[];
}

export interface IMarkerField {
	readonly range:number[];
	readonly color:string[];
	readonly title?:string;
	readonly description?:string;
}

export interface IMarkerContent {
	readonly location:string;
	readonly data:Record<string,MarkerData>;
	readonly title?:string;
	readonly description?:string;
	readonly image?:string;
}

export type MarkerData = Readonly<{
	avg:number;
	std:number;
	n:number;
}>;

export class MarkerLoader {

	public static parseConfig(ob:any):IMarkerConfig {
		return {
			fields:parseFields(ob["fields"]),
			markers:parseMarkers(ob["markers"])
		};
	}

	private constructor(){}

}


const parseMarkers = (ob:any):IMarkerContent[] => {
	if(!ob){ return []; }
	return ob; // todo: validation
	// return [];
};


const parseFields = (ob:any):Record<string,IMarkerField> => {
	if(!ob){ return {}; }
	return ob; // todo: validation
	// return {};
};