import { DivIcon } from "leaflet";
import tinycolor from "tinycolor2";


export class LeafletUtils {

	public static getMarkerIcon(text:string, color:string = "#fff"){
		return getMarkerIcon({ text, color });
	}

	private constructor(){}
}



const getMarkerIcon = (options:{
	text:any;
	color:string;
}) => {
	const color = options.color;
	const tcolor = tinycolor(color).isDark() ? "#ffffff" : "#000000";
	const txt = options.text || "";
	const size = "3em";
	const tsize = "1em";
	return new DivIcon({
		html:`
		<div class='shadow'>
			<div style='position:absolute;left:50%;top:100%;' class="shadow">
				<div style="position:absolute;bottom:100%;transform:translate(-50%,0)">
					<div
					style="border-width:2px !important; font-size:${tsize};width:${size};height:${size};background:${color};color:${tcolor}"
					class="rounded-circle d-flex hoverable shadow">
						<span class='m-auto'>
							${txt}
						</span>
					</div>
					<div class="d-flex">
						<div style="height:0.5em;width:3px;background:${color}" class="m-auto"/>
					</div>
				</div>
			</div>

		</div>`
	});
};