import { FC } from "react";
import styled from "styled-components";
import { PageView } from "./PageView";
import { routes } from "./routes";

export const App:FC = () => {
	return (
		<Providers>
			<Container>
				<PageView routes={ routes }/>
			</Container>
		</Providers>
	);
};

const Providers:FC = (props) => {
	return (
		<div>
			{ props.children }
		</div>
	);
};

const Container = styled.div`
	/* height: 100vh;
	width: 100vw; */
`;

export default App;