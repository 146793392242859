import tinycolor from "tinycolor2";

export class ColorUtils {

	public static lerp(c1:string, c2:string, t:number):string{
		const cc1 = tinycolor(c1).toRgb();
		const cc2 = tinycolor(c2).toRgb();
		const r = lerpNum(cc1.r, cc2.r, 1 - t);
		const g = lerpNum(cc1.g, cc2.g, 1 - t);
		const b = lerpNum(cc1.b, cc2.b, 1 - t);
		return tinycolor({ r,g,b, a:1 }).toHexString();
	}

	public static lerpGradient(colors:string[], range:number[], value:number){
		const [ c1, c2 ] = colors;
		const [ min, max ] = range;
		const v = value / (Math.abs(max - min) || 1);
		return ColorUtils.lerp(c1, c2, v);
	}

	private constructor(){}

}

const lerpNum = (x:number, y:number, t:number) => x * t + y * (1 - t);