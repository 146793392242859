import { FC } from "react";
import { BGImage, Box, Icon } from "@ui";
import { GUID } from "@utils";
import { IMarkerConfig, IMarkerContent } from "./MarkerLoader";
import { MarkerFieldData } from "./MarkerFieldData";
import styled from "styled-components";
import { TooltipWrapper } from "../widgets";

export const MarkerContent:FC<{
	config:IMarkerConfig;
	marker:IMarkerContent;
}> = (props) => {

	const {
		marker,
		config,
	} = props;

	const img = marker.image || "";

	const title = marker.title ? (
		<h6>{ marker.title }</h6>
	) : null;
	
	const links = [
		{
			icon:"google-maps",
			href:`https://maps.google.com/maps?q=${marker.location}`,
			tooltip:"Google Maps...",
			variant:"success"
		}
	];

	if(img){
		links.unshift({
			icon:"arrow-expand",
			href:img,
			tooltip:"Image...",
			variant:"dark"
		});
	}


	const dlinks = links.map(l => {
		return (
			<IconLink
			key={ GUID.getGUID() }
			icon={ l.icon }
			href={ l.href }
			tooltip={ l.tooltip }
			variant={ l.variant }
			className="ms-1"
			/>
		);
	});

	return (
		<div>
			{ title }
			<MarkerImage src={ img }>
				<div className="w-100 h-100 d-flex">
					<div className="ms-auto mt-auto d-flex">
						{ dlinks }
					</div>
				</div>
			</MarkerImage>
			<MarkerData
			config={ config }
			marker={ marker }
			className="mt-1"
			/>
		</div>
	);
};

const MarkerImage:FC<{
	src:string;
}> = (props) => {

	const img = props.src ? (
		<BGImage url={ props.src } className="rounded"/>
	) : (
		<Box.Abs className="bg-light rounded"/>
	);

	return (
		<Box.Ratio
		ratio={ 30 }
		className="rounded border border-dark bg-dark"
		style={{ overflow:"hidden" }}
		>
			{ img }
			<Box.Abs
			className="p-2"
			>
				{ props.children }
			</Box.Abs>
		</Box.Ratio>
	);
};

const IconLink:FC<{
	icon:string;
	href:string;
	tooltip:string;
	variant?:string;
	className?:string;
}> = (props) => {

	const cls = `${props.className} shadow bg-${props.variant}`;

	return (
		<StyledLink href={ props.href } target="_" className={ cls }>
			<TooltipWrapper
			text={ props.tooltip }
			>
				<div className="wrapper">
					<Icon name={ props.icon } className="my-auto icon"/>
				</div>
			</TooltipWrapper>
		</StyledLink>
	);
};

IconLink.defaultProps = {
	variant:"primary"
};

const StyledLink = styled.a`
	text-decoration: none;
	color:#fff !important;
	border-radius: 0.5em;
	opacity:0.9;

	.wrapper {
		padding:0 0.25em;
	}
	
	.icon {
		font-size: 1em;
	}

	&:hover {
		opacity:1;
		background:#fff !important;
		color:#000 !important;

		transition:all .2s;
	}
`;

const MarkerData:FC<{
	config:IMarkerConfig;
	marker:IMarkerContent,
	className?:string;
}> = (props) => {

	const {
		marker,
	} = props;

	const datafields = Object.keys(props.marker.data)
	.map(dk => {
		return {
			key:dk,
			field:props.config.fields[dk],
			data:props.marker.data[dk],
		};
	});

	const drows = datafields.map(df => {
		return (
			<MarkerFieldData
			key={ GUID.getGUID() }
			fieldKey={ df.key }
			field={ df.field }
			data={ df.data }
			className="mb-1"
			/>
		);
	});

	const description = marker.description ? (
		<IconParagraphBox
		icon="information-variant"
		text={ marker.description }
		/>
	) : null;

	const cls = `${props.className}`;

	return (
		<div className={ cls }>
			{ drows }
			{ description }
		</div>
	);
};


const IconParagraphBox:FC<{
	icon:string;
	text:string;
}> = (props) => {

	const {
		icon,
		text,
	} = props;


	return (
		<div className="mt-4 border border-dark p-2 shadow rounded"
		style={{
			position:"relative"
		}}
		>
			<div style={{
				position:"absolute",
				left:"50%",
				top:"0",
				transform: "translate(-50%,-50%)",
			}}>
				<h5 className="m-0 text-light bg-dark rounded-circle"
				style={{
					padding:"0 0.1em"
				}}
				>
					<Icon name={ icon }/>
				</h5>
			</div>

			<p className="m-0">
				<small>
					{ text }
				</small>
			</p>
		</div>
	);
};