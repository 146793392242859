import "leaflet/dist/leaflet.css";
import { FC, useState, useMemo } from "react";
import { Map, LatLng } from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Box } from "@ui";
import { useKeyPress, useRefresh } from "../hooks";
import { TileProvider } from "../types";
import { GUID } from "@utils";
import { ColorUtils, LeafletUtils } from "../utils";
import {
	IMarkerContent,
	MarkerLoader,
} from "./MarkerLoader";
import { FilterSidebar } from "./FilterSidebar";
import { MarkerContent } from "./MarkerContent";

const mapPositions = {
	iceland:{
		lat:64.965,
		lng:-19,
	}
};

export const MarkerMap:FC<{
	args?:any;
}> = (props) => {

	const config = MarkerLoader.parseConfig(props.args || {});
	const markers = config.markers;
	const stats = config.fields;
	const defaultStat:string|undefined = Object.keys(stats)[0];;

	const getDefaultCenter = () => {
		const [ m ] = markers;
		if(m){
			const [ lat, lng ] = m.location
			.split(",")
			.map(s => s.trim())
			.map(s => Number(s));
			return new LatLng(lat, lng);
		}
		return mapPositions.iceland;
	};

	const getDefaultZoom = () => markers.length > 0 ? 15 : 8;

	const [ tileProvider, setTileProvider ] = useState<string>(TileProvider.CartoLight);
	const [ center, setCenter ] = useState(getDefaultCenter());
	const [ zoom, setZoom ] = useState(getDefaultZoom());
	const [ map, setMap ] = useState<Map|null>(null);
	const [ activeStatName, setActiveStat ] = useState(defaultStat || "");

	const activeStat = useMemo(() => {
		return stats[activeStatName] || null;
	}, [ activeStatName ]);

	const getMarkerText = (m:IMarkerContent) => {
		if(!activeStat){ return "-"; }
		const statData = m.data[activeStatName];
		if(!statData){ return "#fff"};
		const statValue = statData.avg;
		return statValue !== undefined ? statValue.toString() : "-";
	};

	const getMarkerColor = (m:IMarkerContent) => {
		if(!activeStat){ return "#fff"; }
		if(!activeStat.color || !activeStat.range){ return "#fff"; }
		const statData = m.data[activeStatName];
		if(!statData){ return "#fff"};
		const statValue = statData.avg;
		return ColorUtils.lerpGradient(activeStat.color || [], activeStat.range, statValue);
	};

	const changeActiveStat = (k:string) => {
		setActiveStat(k);
		refreshMap();
	};

	const {
		refreshKey:mapKey,
		refresh:refreshMap,
	} = useRefresh();

	[
		TileProvider.CartoLight,
		TileProvider.GoogleSatellite,
	]
	.forEach((url, i) => {
		useKeyPress({
			key:(i+1).toString(),
			onKeyDown(){
				setTileProvider(url);
				refreshMap();
			}
		});
	});

	const statMarkers = markers
	.map(m => {
		const color = getMarkerColor(m);
		const text = getMarkerText(m);
		const ic = LeafletUtils.getMarkerIcon(text, color);

		const [ lat, lng ] = m.location
		.split(",")
		.map(s => s.trim())
		.map(s => Number(s));

		const pos = new LatLng(lat, lng);

		return (
			<Marker
			key={ GUID.getGUID() }
			position={pos}
			icon={ic}
			riseOnHover
			>
				<Popup className="p-0">
					<div style={{ width:"300px" }}>

						<MarkerContent
						config={ config }
						marker={ m }
						/>
					</div>
				</Popup>
			</Marker>
		);
	});

	const overlay = (
		<Box.Abs
		style={{ pointerEvents:"none", overflow:"hidden" }}
		className="p-3x"
		>
			<FilterSidebar
			activeField={ activeStatName }
			fields={ stats }
			onSelectField={ changeActiveStat }
			/>
		</Box.Abs>
	);

	const dmap = useMemo(() => (
		<MapContainer
		center={ center }
		zoom={ zoom }
		zoomAnimation={false}
		scrollWheelZoom={ true }
		zoomControl={ false }
		className="w-100 h-100"
		attributionControl={ false }
		style={{ zIndex:0 } as any}
		whenCreated={ setMap }
		key={ mapKey }
		>
			<TileLayer url={ tileProvider }/>
			{ statMarkers }
		</MapContainer>
	), [ mapKey ]);

	return (
		<div
		className="w-100 h-100 d-flex"
		style={{ position:"relative" }}
		>
			<Box.Abs>
				{ dmap }
				{ overlay }
			</Box.Abs>
		</div>
	);
};