import { Icon } from "@ui";
import { GUID } from "@utils";
import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import { CodeBlock } from "./CodeBlock";
import { MarkerImport } from "./MarkerImport";

const sepOptions = [ ",", ";", "|" ];

enum IndentMode {
	Tab = "t",
	Space2 = "s2",
	Space4 = "s4",
};

const indentOptions = [
	{ val:IndentMode.Tab, text:"Tab" },
	{ val:IndentMode.Space2, text:"2 Spaces" },
	{ val:IndentMode.Space4, text:"4 Spaces" },
	{ val:undefined, text:"None" },
];

type FieldMetadata = {
	title:string;
	description:string;
	minValue:number;
	maxValue:number;
	color1:string;
	color2:string;
};

const fieldDefaults:FieldMetadata = {
	title:"",
	description:"",
	color1:"#ff0000",
	color2:"#00ff00",
	minValue:0,
	maxValue:9,
};



const outputHelp = `
Copy and upload the output below some place and use it like so:
<pre class="m-0"><b>${window.location.origin}/rank?cfg=&lt;path_to_config&gt;</b></pre>
`.trim();

const exampleInput = `
location,image,title,description,data_mydata1_avg,data_mydata1_std,data_mydata1_n,data_mydata2_avg,data_mydata2_std,data_mydata2_n
64.150784;-21.944571,,Some Spot,Test description 1,5.6,2,40,5.6,2,40
64.1453256;-21.9532875,,Another Spot,Test description 2,5.6,2,40,5.6,2,40
`.trim();

export const ImportMarkerData:FC = () => {

	const [ input, setInput ] = useState(exampleInput);
	const [ separator, setSeparator ] = useState(sepOptions[0]);
	const [ errors, setErrors ] = useState<string[]>([]);
	const [ indentMode, setIndentMode ] = useState<string|undefined>("t");
	const [ dataKeys, setDatakeys ] = useState<string[]>([]);
	const [ markers, setMarkers ] = useState<any[]>([]);
	const [ fieldMeta, setFieldMeta ] = useState<Record<string,FieldMetadata>>({});


	const getIndent = (mode?:string) => {
		switch(mode){
			case IndentMode.Space2: return "  ";
			case IndentMode.Space4: return "    ";
			case IndentMode.Tab: return "\t";
		}
		return undefined;
	};

	const output = useMemo<string|null>(() => {

		if(errors.length > 0){ return null; }

		const fields:any = {};

		Object.keys(fieldMeta)
		.forEach(fk => {

			const field = fieldMeta[fk];
			fields[fk] = {
				color:[ field.color1, field.color2 ],
				range:[ Number(field.minValue), Number(field.maxValue) ],
				title:field.title,
				description:field.description,
			};
		});

		const indent = getIndent(indentMode);

		const r = {
			fields,
			markers,
		};
		return indent ? JSON.stringify(r, null, indent) : JSON.stringify(r);
	}, [ fieldMeta, markers, indentMode, errors ]);


	const handleConfirmImport = () => {

		setErrors([]);

		try {
			const o = MarkerImport.importCSV(input, separator);

			if(o.errors.length > 0){
				setErrors(o.errors);
				return;
			}

			setMarkers(o.markers);
			setDatakeys(o.dataKeys);
			const fm = fieldMeta;

			o.dataKeys.forEach(k => {
				if(!Boolean(fm[k])){
					fm[k] = {
						...fieldDefaults,
					};
				}
			})

		}
		catch(err){
			setErrors([ "Error parsing input"]);
		}
	};

	const handleEditInput = (v:string) => {
		setInput(v);

		// if(errors.length > 0){
		// 	setErrors([]);
		// }
	};

	const seps = sepOptions.map(s => (
		<option value={ s } key={ s }>
			{ s }
		</option>
	));

	const dindents = indentOptions.map(o => (
		<option value={ o.val } key={ o.text }>
			{ o.text }
		</option>
	));
	

	const handleEditFieldProperty = (fieldKey:string, prop:string, value:any) => {


		const fields = {
			...fieldMeta,
		};

		const field = fields[fieldKey];
		(field as any)[prop] = value;
		
		fields[fieldKey] = field;
		setFieldMeta(fields);

	};

	const dfields = dataKeys.map(k => {

		const field = fieldMeta[k];

		return (
			<div
			key={ k }
			className="d-flex my-1"
			>
				<div className="input-group input-group-sm">

					<span className="input-group-text" >
						{ k }
					</span>
					<input
					placeholder={ "Title..." }
					value={ field["title"] }
					className="form-control"
					onChange={ e => {
						handleEditFieldProperty(k, "title", e.target.value)
					}}
					/>

					<input
					placeholder={ "Description..." }
					value={ field["description"] }
					className="form-control"
					spellCheck={ false }
					onChange={ e => {
						handleEditFieldProperty(k, "description", e.target.value)
					}}
					/>

					<input
					type="number"
					value={ field["maxValue"] }
					min={1}
					className="form-control"
					onChange={ e => {
						handleEditFieldProperty(k, "maxValue", e.target.value)
					}}
					/>

					<input
					type="color"
					className="form-control form-control-color"
					value={ field["color1"] }
					onChange={ e => {
						handleEditFieldProperty(k, "color1", e.target.value)
					}}
					/>
					<input
					type="color"
					className="form-control form-control-color"
					value={ field["color2"] }
					onChange={ e => {
						handleEditFieldProperty(k, "color2", e.target.value)
					}}
					/>
				</div>
				
			</div>
		)
	});

	const dataSettings:Record<string,JSX.Element> = {
		"Markers (CSV)":(
			<StyledTextArea
			spellCheck={ false }
			rows={5}
			className="form-control"
			value={ input }
			onChange={ e => handleEditInput(e.target.value) }
			/>
		),
		"Separator":(
			<select
			onChange={ e => setSeparator(e.target.value) }
			className="form-control"
			>
				{ seps }
			</select>
		),
	};

	const outputSettings:Record<string,JSX.Element> = {
		"Indent":(
			<select
			onChange={ e => setIndentMode(e.target.value) }
			className="form-control"
			>
				{ dindents }
			</select>
		),
	};

	const ddatasettings = Object.keys(dataSettings)
	.map(k => (
		<div key={ k } className="mb-3">
			<PropLabel>
				{ k }
			</PropLabel>
			{ dataSettings[k] }
		</div>
	));

	const doutputsettings = Object.keys(outputSettings)
	.map(k => (
		<div key={ k } className="mb-3">
			<PropLabel>
				{ k }
			</PropLabel>
			{ outputSettings[k] }
		</div>
	));

	const derrors = errors.map(err => (
		<li key={ GUID.getGUID() }>{ err }</li>
	))

	const derror = errors.length > 0 ? (
		<div className="bg-danger text-light p-3 d-flex">

			<div>
				<h1 className="m-0">
					<Icon name="alert"/>
				</h1>
			</div>
			<div className="flex-fill">
				<ul>
					{ derrors }
				</ul>
			</div>

		</div>
	) : null;


	const doutput = output ? (
		<>
			<HelpBox>
				<div dangerouslySetInnerHTML={{ __html:outputHelp }}
				className="m-0"
				/>
			</HelpBox>

			<div className="my-2"/>

			<CodeBlock text={ output }/>

		</>
	) : null;

	return (
		<div
		className="container py-3"
		>

			<SectionHeader>
				Data
			</SectionHeader>

			<hr/>

			{ ddatasettings }

			<div className="py-1 d-flex justify-content-end">
				<button
				onClick={ handleConfirmImport }
				className="btn btn-primary shadow-none"
				>
					Load...
				</button>
			</div>
			
			{ dataKeys.length > 0 && <>
			
				{/* <hr/> */}

				<PropLabel>
					Data Fields
				</PropLabel>

				{ dfields }

				<div className="mb-4"/>

			</>}

			{/* <hr/> */}

			
			<SectionHeader>
				Output
			</SectionHeader>

			<hr/>

			{ doutputsettings }

			{ derror }
			{ doutput }


		</div>
	);
};





const StyledTextArea = styled.textarea`



`;


const SectionHeader = styled.h1``;


const PropLabel = styled.h5`

`;



const HelpBox:FC = (props) => {


	return (
		<div className="d-flex border rounded "
		style={{
			overflow:"hidden",
			background:"rgba(0,0,255,0.01)"
		}}
		>

			<div className="p-1 px-3 d-flex bg-info text-light">
				<h5 className="m-auto">
					<Icon name="information-variant"/>
				</h5>
			</div>

			<div className="flex-fill p-2 my-auto">
				{ props.children }
			</div>

		</div>
	);

};