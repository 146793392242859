import { Box } from "@ui";
import { FC } from "react";
import styled from "styled-components";

export const PreviewLikert:FC<{
	min:number;
	max:number;
}> = (props) => {

	const nodes:number[] = [];

	const {
		min,
		max,
	} = props;

	const count = Math.abs(max - min);

	for(let i = 0; i <= count; i++){
		nodes.push(i);
	}

	const dnodes = nodes.map((_,i) => {
		return (
			<LikertNode key={ i.toString() }/>
		);
	});

	return (
		<div className="d-flex"
		style={{ opacity:0.2 }}
		>
			<ValueLabel>{ min }</ValueLabel>
			<LikertWrapper className="my-auto">
				<Box.Abs className="d-flex">
					<LikertLine/>
				</Box.Abs>
				<Box.Abs className="d-flex justify-content-between">
					{ dnodes }
				</Box.Abs>
			</LikertWrapper>
			<ValueLabel>{ max }</ValueLabel>
		</div>
	);
};

const ValueLabel = styled.small`
	margin:auto 0.5em;
	font-size:0.8em;
	font-weight:bold;
`;

const LikertWrapper = styled.div`
	width:100%;
	height:0.5em;
	position:relative;
`;

const LikertLine = styled.div`
	height:1px;
	width:100%;
	margin:auto 0;
	background:black;
`;

const LikertNode = styled.div<{
	size?:number;
}>`
	width:${({ size }) => size}em;
	height:${({ size }) => size}em;
	border-radius:50%;
	border:1px solid rgba(0,0,0,1);
	background:black;
`;

LikertNode.defaultProps = {
	size:0.5,
};