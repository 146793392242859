import { FC, CSSProperties } from "react";
import styled from "styled-components";


type RectBounds = {
	x:number;
	y:number;
	width:number;
	height:number;
}

type Props = Partial<RectBounds> & {
	stroke?:string;
	fill?:string;
	dashed?:boolean;
};

const defaults:Required<Props> = {
	fill:"#ffffff1a",
	stroke:"#ffffff",
	dashed:false,
	x:0,
	y:0,
	width:0,
	height:0,
};

export const SelectionRect:FC<Props> = (props) => {

	const {
		stroke,
		fill,
		dashed,
	} = {
		...defaults,
		...props,
	}

	const style:CSSProperties = {
		borderColor:stroke,
		background:fill,
		borderStyle:dashed ? "dashed" : "solid",
		left:`${props.x}px`,
		top:`${props.y}px`,
		width:`${props.width}px`,
		height:`${props.height}px`,
	};

	return (
		<StyledRect
		style={ style }
		/>
	);

};


const StyledRect = styled.div`
	position:absolute;
	/* background:rgba(255,255,255,0.1); */
	border:1px solid transparent;
	pointer-events:none;
`;
