import { FC } from "react";
import { Icon } from "../../@ui";
import {
	IMarkerField,
} from "./MarkerLoader";
import styled from "styled-components";
import { PreviewLikert } from "./PreviewLikert";

export const FieldBox:FC<{
	active?:boolean;
	fieldKey:string;
	field:IMarkerField;
	onSelect?:() => void;
}> = (props) => {

	const {
		field,
	} = props;

	const [ min, max ] = field.range;
	const title = props.field.title || props.fieldKey;
	const description = props.field.description ? (
		<p
		style={{ fontStyle:"italic" }}
		className="m-0 description"
		>
			<small>
				{ props.field.description }
			</small>
		</p>
	) : null;
	
	const cls = `d-flex rounded ${props.active ? "active shadow" : ""}`;

	return (
		<FieldBoxWrapper className={ cls }
		style={{ overflow:"hidden" }}
		onClick={ () => props.onSelect && props.onSelect() }
		>
			<div className="bgbox d-flex px-1">
				<h4 className="m-auto">
					<Icon name="chat"/>
				</h4>
			</div>
			<div className="flex-fill">
				<div className="p-2">
					<h6 className="title">
						{ title }
					</h6>
					{ description }
				</div>
				<div className="mt-2x bgboxx p-2">
					<PreviewLikert
					min={ min }
					max={ max }
					/>
				</div>
			</div>
		</FieldBoxWrapper>
	);
};

const FieldBoxWrapper = styled.button`
	all:unset;
	width:100%;
	cursor:pointer;
	border:1px solid transparent;

	.title {
		font-size:0.8em;
	}

	.description {
		font-size:0.8em;
	}

	&:hover {
		background:rgba(0,0,0,0.1);
	}

	&:not(.active){
		border-color:rgba(0,0,0,0.1);
		.bgbox {
			
			background:rgba(0,0,0,0.1);
		}
	}

	&.active {
		border-color:#007bff;
		.bgbox {
			background:#007bff;
			color:white;
		}
	}
	margin-bottom:0.5em;
`;