import { FC, useState } from "react";
import { Box, Icon } from "@ui";
import { IMarkerField } from "./MarkerLoader";
import styled from "styled-components";
import { FieldBox } from "./FieldBox";

export const FilterSidebar:FC<{
	activeField:string;
	fields:Record<string,IMarkerField>;
	onSelectField:(v:string) => void;
}> = (props) => {

	const [ open, setOpen ] = useState(true);

	const {
		activeField,
		fields,
	} = props;

	const fieldBoxes = Object.keys(fields)
	.map(fk => {
		return (
			<FieldBox
			key={ fk }
			fieldKey={ fk }
			active={ fk === activeField }
			field={ fields[fk] }
			onSelect={ () => props.onSelectField(fk) }
			/>
		);
	});

	const content = open ? (
		<div className="d-flex flex-column h-100">
			<div className="d-flex p-1 border-bottom">
				<button
				onClick={ () => setOpen(false) }
				className="ms-auto btn btn-outline-dark shadow-none border-0"
				>
					<Icon name="menu-open"/>
				</button>
			</div>
			<Box.RelFill>
				<ScrollBox>
					<div className="p-2">
						{ fieldBoxes }
					</div>
				</ScrollBox>
			</Box.RelFill>
		</div>
	) : (
		<div
		className="h-100 d-flex"
		>
			<button
			onClick={ () => setOpen(true) }
			className="my-auto btn btn-outline-dark shadow-none border-0 h-100 rounded-0"
			>
				<Icon name="menu"/>
			</button>
		</div>
	);

	const cls = `shadow ${ open ? "" : "closed" }`;

	return (
		<SidebarWrapper
		className={ cls }
		>
			{ content }
		</SidebarWrapper>
	);
};

const SidebarWrapper = styled.div`
	pointer-events: all;
	height:100%;
	position:absolute;
	background:white;
	border-right:1px solid #a8a8a8;
	&:not(.closed){
		width:300px;
	}
`;

const ScrollBox = styled.div`
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	overflow-y:auto;
`;