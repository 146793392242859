
export class GeoUtils {

	static lngToTileIndex(lng:number, zoom:number, round:boolean = true){
		const r = (lng + 180) / 360 * Math.pow(2, zoom);
		return round ? Math.floor(r) : r;
	};

	static latToTileIndex(lat:number, zoom:number, round:boolean = true){
		const pi =  Math.PI;
		const x1 = Math.tan(lat * pi / 180);
		const x2 = Math.cos(lat * pi / 180);
		const x3 = Math.log(x1 + 1 / x2);
		const zp = Math.pow(2, zoom);
		const r = (1 - x3 / pi) / 2 * zp;
		return round ? Math.floor(r) : r;
	};

	private constructor(){}
}
