import { FC, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MarkerMap } from "@maps";
import { useLocation } from "react-router";

const Page:FC = () => {

	const [ cfg, setConfig ] = useState<any>();
	const [ loading, setLoading ] = useState(false);

	const search = useLocation().search;

	const url = new URLSearchParams(search).get("cfg");

	if(url){
		useEffect(() => {
			(async () => {
				setLoading(true);
				const c = await fetchConfig(url);
				setConfig(c);
				setLoading(false);
			})();
		}, []);
	}

	if(loading){
		return <div/>
	}

	return (
		<AppRoot>
			<MarkerMap args={ cfg }/>
		</AppRoot>
	);
};

const AbsStyle = css`
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
`;

const AppRoot = styled.div`
	${AbsStyle};
`;

export default Page;

const fetchConfig = async(url:string):Promise<any> => {
	try {
		const r = await fetch(url);
		return await r.json();
	}
	catch(err){ console.log(err); }
	return null;
}