import { LatLng } from "leaflet";

export type BBox = {
	x:number;
	y:number;
	w:number;
	h:number;
}

export type MapCoordinate = {
	lat:number;
	lng:number;
}

export type SavedSelection = {
	key:string;
	view:{
		zoomLevel:number;
	};
	bounds:LatLng[];
};


export enum TileProvider {
	GoogleMap = "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
	GoogleSatellite = "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
	OpenStreetMaps = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
	OpenTopo = "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
	HillShading = "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
	CartoLight = "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
}

export type TileRef = {
	x:number;
	y:number;
	z:number;
};