import { FC } from "react";
import styled, { css } from "styled-components";
import { SelectionMap } from "@maps";

const Page:FC = () => {
	return (
		<AppRoot>
			<SelectionMap/>
		</AppRoot>
	);
};


const AbsStyle = css`
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
`;

const AppRoot = styled.div`
	${AbsStyle};
`;


export default Page;