
import { FC, useState, useMemo } from "react";
import styled from "styled-components";
import { Map } from "leaflet";
import { MapConsumer, MapContainer, TileLayer } from "react-leaflet";
import { GUID } from "../@utils";
import { Box, Icon } from "../@ui";
import * as Utils from "./utils";
import { useDragSelect, useKeyPress, useRefresh } from "./hooks";
import { BBox, SavedSelection, TileProvider } from "./types";
import { SelectionRect } from "./SelectionRect";
import { MapEvents } from "./MapEvents";

const mapPositions = {
	iceland:{
		lat:64.965,
		lng:-19,
	}
};

export interface IMapViewer {}

export const SelectionMap:FC<IMapViewer> = (props) => {

	const [ tileProvider, setTileProvider ] = useState<string>(TileProvider.GoogleSatellite);
	const [ center, setCenter ] = useState(mapPositions.iceland);
	const [ zoom, setZoom ] = useState(7);
	const [ map, setMap ] = useState<Map|null>(null);
	const [ selections, setSelections ] = useState<SavedSelection[]>([]);
	const [ activeHistoryKey, setActiveHistoryKey ] = useState<string>();

	const {
		refreshKey:moveKey,
		refresh:refreshMovement,
	} = useRefresh();

	const {
		refreshKey:mapKey,
		refresh:refreshMap,
	} = useRefresh();

	const handleMovement = (map:Map) => {
		setCenter(map.getCenter());
		setZoom(map.getZoom());
		refreshMovement();
	};

	useKeyPress({
		key:"f",
		onKeyDown:refreshMap,
	});

	[
		TileProvider.GoogleSatellite,
		TileProvider.GoogleMap,
		TileProvider.OpenStreetMaps,
		TileProvider.HillShading,
		TileProvider.CartoLight,
		TileProvider.OpenTopo,
	]
	.forEach((url, i) => {
		useKeyPress({
			key:(i+1).toString(),
			onKeyDown(){
				setTileProvider(url);
				refreshMap();
			}
		});
	});

	const activeSelection = useMemo(() => {
		const item = selections.find(it => it.key === activeHistoryKey) || null;
		if(!map || !item){ return null; }
		return Utils.getBoundsFromCoordinates(item.bounds, map);
	}, [ activeHistoryKey, selections, moveKey ]);

	const clearSelectionIndex = (i:number) => {
		selections.splice(i, 1);
		setSelections([ ...selections ]);
	};

	const setSelectionKey = (k:string) => {
		const item = selections.find(it => it.key === k) || null;
		if(!map || !item){ return null; }

		const center = Utils.getCenterOfArea(item.bounds);

		map.setView(center, item.view.zoomLevel, {
			animate:true,
		});

		setActiveHistoryKey(k);
	}

	const createSelection = (bb:BBox) => {
		if(!map){ return }
		const b = Utils.getCoordinatesFromBounds(bb, map);
		const key = GUID.getGUID();
		setSelections([
			{
				key,
				view:{
					zoomLevel:map.getZoom(),
				},
				bounds:b,
			},
			...selections,
		]);
		setActiveHistoryKey(key);
	};

	const hcanvas = useMemo(() => {
		if(!activeSelection || !map){ return;}

		return (
			<Box.Abs
			style={{ pointerEvents:"none", overflow:"hidden" }}
			>
				<SelectionRect
				x={ activeSelection.x }
				y={ activeSelection.y }
				width={ activeSelection.w }
				height={ activeSelection.h }
				/>
			</Box.Abs>
		);
	}, [ activeSelection, map ]);

	const dmap = useMemo(() => (
		<MapContainer
		center={ center }
		zoom={ zoom }
		zoomAnimation={false}
		scrollWheelZoom={ true }
		zoomControl={ false }
		className="w-100 h-100"
		attributionControl={ false }
		style={{ zIndex:0 } as any}
		whenCreated={ setMap }
		key={ mapKey }
		>
			<TileLayer url={ tileProvider }/>
			<MapConsumer>
				{(map) => {

					return (
						<MapEvents onMove={ () => handleMovement(map) }/>
					)
				}}
			</MapConsumer>
			{ props.children }
		</MapContainer>
	), [ mapKey ]);

	return (
		<div
		className="w-100 h-100 d-flex map grid"
		style={{ position:"relative" }}
		>
			<Box.Abs>
				{ dmap }
			</Box.Abs>
			
			<SelectionOverlay onSelect={ createSelection }/>

			{ hcanvas }

			<Box.Abs
			style={{ pointerEvents:"none" }}
			className="d-flex"
			>
				<SelectionList
				items={ selections }
				activeKey={ activeHistoryKey }
				onClearItem={ clearSelectionIndex }
				onSelectKey={ setSelectionKey }
				tilePath={ tileProvider }
				/>
				<div className="flex-fill"/>
			</Box.Abs>
		</div>
	);
};

const SelectionOverlay:FC<{
	onSelect?:(b:BBox) => void;
}> = (props) => {

	const [ selecting, setSelecting ] = useState(false);

	useKeyPress({
		key:"Shift",
		onKeyDown: () => setSelecting(true),
		onKeyUp: () => setSelecting(false),
	});

	const {
		ref,
		bounds,
		active,
	} = useDragSelect<HTMLDivElement>({
		onDone(){
			if(props.onSelect){
				props.onSelect(bounds)
			}
		}
	});

	const box = active ? (
		<SelectionRect
		x={ bounds.x }
		y={ bounds.y }
		width={ bounds.w }
		height={ bounds.h }
		dashed
		fill="#ffffff4d"
		/>
	) : null;

	return selecting ? (
		<Box.Abs ref={ ref }>
			{ box }
		</Box.Abs>
	) : null;
};

const SelectionList:FC<{
	items:SavedSelection[];
	activeKey?:string;
	tilePath:string;
	onSelectKey?:(k:string) => void;
	onClearItem?:(i:number) => void;
}> = (props) => {

	const handleRemove = (k:string) => {
		const i = props.items.findIndex(it => it.key === k);
		if(i < 0){ return; }
		if(props.onClearItem){ props.onClearItem(i); }
	};

	const handleSelect = (k:string) => {
		if(props.onSelectKey){ props.onSelectKey(k); }
	}

	if(props.items.length === 0){ return null; }

	const sItems = props.items
	.map(item => {
		const active = props.activeKey === item.key;
		return (
			<SavedSelectionItem
			key={ item.key }
			active={ active }
			onClick={ () => handleSelect(item.key) }
			onRemove={ () => handleRemove(item.key) }
			item={ item }
			tilePath={ props.tilePath }
			/>
		);
	});

	return (
		<div className="p-0 h-100 shadow"
		style={{
			overflow:"auto",
			pointerEvents:"all",
			background:"#ffffffb3"
		}}
		>
			<div className="list-group rounded-0">
				{ sItems }
			</div>
		</div>
	);
};

const SavedSelectionItem:FC<{
	active?:boolean;
	item:SavedSelection;
	tilePath:string;
	onClick:() => void;
	onRemove:() => void;
}> = (props) => {

	const handleCopy = () => {

		const b = props.item.bounds;

		const data = {
			view:props.item.view,
			area:{
				// lat:{
				// 	min:Math.min(b[0].lat, b[1].lat),
				// 	max:Math.max(b[0].lat, b[1].lat),
				// },
				// lng:{
				// 	min:Math.min(b[0].lng, b[1].lng),
				// 	max:Math.max(b[0].lng, b[1].lng),
				// }
				lat:[
					Math.min(b[0].lat, b[1].lat),
					Math.max(b[0].lat, b[1].lat),
				].join(","),
				lng:[
					Math.min(b[0].lng, b[1].lng),
					Math.max(b[0].lng, b[1].lng),
				].join(","),
			},
		}
		Utils.copyContent(data);

	};
	
	const handleImage = () => {
		Utils.getImageFromArea(props.item.bounds, props.item.view.zoomLevel, props.tilePath);
	};

	const handleClear = () => props.onRemove();

	const btns = [
		{ icon:"eye", fn:props.onClick, },
		{ icon:"content-copy", fn:handleCopy, },
		{ icon:"file-image", fn:handleImage, },
		{ icon:"close", fn:handleClear, }
	]
	.map(it => (
		<IconButton key={ GUID.getGUID() } onClick={ it.fn }>
			<Icon name={ it.icon }/>
		</IconButton>
	));

	const cls = `${props.active ? "active" : ""} list-group-item d-flex p-1`;

	return (
		<div className={ cls }>
			{ btns }
		</div>
	);
};

const IconButton = styled.button`
	all:unset;
	font-family: consolas;
	transition:0.2s;
	padding:0.2em 0.5em;
	border-radius:0.25em;
	font-size: 0.8em;
	&:hover {
		background:rgba(0,0,0,0.1);
	}
`;