import styled from "styled-components";

export const BGImage = styled.div<{ url: string }>`
	width: 100%;
	height: 100%;
	position: relative;
	background-image: url("${({ url }) => url}");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;