import { GUID } from "@utils";
import { Box, Icon } from "@ui";
import { FC, useState } from "react";
import styled from "styled-components";
import { GradientSlider } from "../widgets";
import {
	IMarkerField,
	MarkerData,
} from "./MarkerLoader";
import { TooltipWrapper } from "../widgets";

const normalizeValue = (v:number, min:number, max:number) => {
	const t = max - min;
	return (v - min) / t;
};

export const MarkerFieldData:FC<{
	fieldKey:string;
	field:IMarkerField;
	data:MarkerData;
	className?:string;
}> = (props) => {

	const [ open, setOpen ] = useState(false);

	const {
		field,
		fieldKey,
	} = props;

	const label = field.title || fieldKey;
	const [ min, max ] = field.range;
	const value = props.data.avg;
	const normalizedValue = normalizeValue(value, min, max);
	const count = props.data.n;

	const footer = [ "std", "avg", "n" ]
	.map(k => `${k} = ${(props.data as any)[k]}`)
	.map(v => (
		<NumLabel key={ GUID.getGUID() }>{ v }</NumLabel>
	));

	const content = open ? (
		<>
			<Divider/>
			<ContentRow>
				<div className="d-flex flex-row">
					<GradientSlider
					colors={ [ "#000", "#fff" ] }
					value={ normalizedValue }
					valueColor="#007bff"
					valueTooltip={ value }
					/>
				</div>

				<div className="mt-2"/>
			</ContentRow>
			<Divider/>
			<SpacedRowBox className="p-2">
				{ footer }
			</SpacedRowBox>
		</>
	) : null;

	const cls = `${props.className} bg-dark text-light shadow`;

	const bstats = [
		{ i:"division", v:value, tt:"Average Value" },
		{ i:"human-male", v:count, tt:"Sample Size" },
	];

	return (
		<FieldBox
		className={ cls }
		>
			<ToggleHeader
			value={ open }
			onToggle={ setOpen }
			>
				<div className="d-flex w-100"
				style={{ minHeight:"1.5em" }}
				>
					<Box.RelFill
					className="flex-fill"
					>
						<Box.Abs>
							<TruncateBox>
								<small className="my-auto">
									{ label }
								</small>
							</TruncateBox>
						</Box.Abs>
					</Box.RelFill>
					<ButtonStats stats={ bstats }/>
				</div>
			</ToggleHeader>
			{ content }
		</FieldBox>
	);
};

const TruncateBox = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const SpacedRowBox = styled.div`
	display:flex;
	justify-content: space-between;
`;

const FieldBox = styled.div`
	border-radius: 0.5em;
`;

const Divider = styled.hr`
	margin:0.25em 0;
`;

const NumLabel = styled.small`
`;

const ContentRow = styled.div`
	padding:0.5em;
`;

const ToggleHeader:FC<{
	value:boolean;
	onToggle:(v:boolean) => void;

}> = (props) => {

	const handleToggle = () => {
		props.onToggle && props.onToggle(!props.value);
	};

	const iname = props.value ? "chevron-down" : "chevron-right";

	return (
		<ToggleButton
		onClick={ handleToggle }
		>
			<div className="p-1 d-flex">
				<Icon name={ iname }/>
				{ props.children }
			</div>
		</ToggleButton>
	);
};

const ToggleButton = styled.button`
	all:unset;
	cursor:pointer;
	width:100%;
	border-radius: 0.5em;
	position:relative;

	&:hover {
		transition: all .2s;
		background:rgba(255,255,255,0.1);
	}
`;

const ButtonStats:FC<{

	stats:{
		i:string;
		v:any;
		tt:string;
	}[]
}> = (props) => {

	const {
		stats,
	} = props;

	const dstats = stats.map(s => (
		<StatBadge
		key={ GUID.getGUID() }
		icon={ s.i }
		value={ s.v }
		className="my-auto ms-1"
		tooltip={ s.tt }
		/>
	))

	return (
		<BoxPinnedTR>
			{ dstats }
		</BoxPinnedTR>
	);
};


const StatBadge:FC<{
	icon:string;
	value:any;
	className?:string;
	tooltip?:string;
}> = (props) => {


	const cls = `${props.className} d-flex align-items-center`;
	
	return (

		<TooltipWrapper
		text={ props.tooltip || "" }
		>
			<BadgeWrapper
			className={ cls }
			>
				
				<div className={ `h-100` } style={{ marginRight:"0.1em" }}>
					<Icon name={ props.icon }/>
				</div>

				<div className="bg-dadrk">
				{ props.value }
				</div>
			</BadgeWrapper>
		</TooltipWrapper>

		
	);
};

const BadgeWrapper = styled.div`
	font-size:0.9em;
	padding-left:0.25em;
	padding-right:0.5em;
	border-radius:0.25em;
	background:white;
	color:black;
	user-select:none;
`;

const BoxPinnedTR = styled.div`
	display:flex;
	justify-content: right;
	align-items: center;
	padding:0 0.5em;
`;
