import { FC } from "react";
import { useMapEvents } from "react-leaflet";
import { LeafletEventHandlerFn } from "leaflet";

export const MapEvents:FC<{
	onMove?:LeafletEventHandlerFn;
}> = (props) => {

	useMapEvents({
		move:props.onMove,
	});

	return null;

};