import { FC } from "react";
import { Box, Icon } from "@ui";
import { ImportMarkerData } from "@maps";
import styled from "styled-components";
import { GUID } from "@utils";


const utils = [
	{
		icon:"upload",
		path:"/utils/import-marker-csv",
		text:"Configure Map Markers from CSV"
	}
];

const Page:FC = () => {
	const dutils = utils.map(u => (
		<ListBox
		href={ u.path }
		className="d-flex col-md-12"
		key={ GUID.getGUID() }
		>
			<div className="d-flex">
				<h1 className="m-auto">
					<Icon name={ u.icon }/>
				</h1>
			</div>

			<div className="flex-fill d-flex px-3">
				<h4 className="my-auto">
					{ u.text }
				</h4>
			</div>

		</ListBox>
	));



	return (
		<Container>
			<div className="container py-3">
				{ dutils }
			</div>
		</Container>
	);
};

export default Page;

const Container = styled(Box.Abs)`
	overflow:auto;
`;

const ListBox = styled.a`

	all:unset;
	
	/* color:black !important; */
	padding:1em;

	cursor:pointer;

	border:1px solid rgba(0,0,0,0.1);
	border-radius:0.5em;

	&:hover {
		background:rgba(0,0,0,0.01);
		transition:all .2s;
		/* color:#6d6d6d !important; */
	}
	



`;