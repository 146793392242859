import { FC } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

export const PageView: FC<{
	routes:{
		path:string;
		component:FC;
	}[];
}> = props => {
	const { routes } = props;

	const droutes = routes.map((route, i) => {
		const { path, component: Component } = route;
		return (
			<Route exact path={path} key={`route-${i}`}>
				<Component />
			</Route>
		);
	});

	return (
		<BrowserRouter>
			<Switch>
				{ droutes }
				<Redirect to="/" />
			</Switch>
		</BrowserRouter>
	);
};