import { FC } from "react";
import { Box } from "@ui";
import { ImportMarkerData } from "@maps";
import styled from "styled-components";

const Page:FC = () => {
	return (
		<Container>
			<ImportMarkerData/>
		</Container>
	);
};

export default Page;

const Container = styled(Box.Abs)`
	overflow:auto;
`;