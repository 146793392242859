
const dataRegex = /^data_(?<key>[a-z0-9_-]+)_(?<subkey>avg|n|std)$/;

const locationRegex = /^((\-?|\+?)?\d+(\.\d+)?);\s*((\-?|\+?)?\d+(\.\d+)?)$/;

const requiredSubkeys = [ "avg", "std", "n" ];

const requiredColumns = [ "location", "image", "title", "description" ];

const fieldFormatters:Record<string, (v:string) => string> = {
	"location":(v:string) => v.replace(";", ",")
};

const validators:Record<string,(v:string) => string|null> = {
	"location":(v:string) => {
		return !v.match(locationRegex) ? `Invalid coordinates '${v}'` : null;
	}
};


export class MarkerImport {

	public static importCSV(text:string, separator:string){

		const csv = new CSVData(text, {
			separator
		});
		return new MarkerImport(csv);
	}

	public get markers(){
		return this._markers;
	}

	public get errors(){
		return this._errors;
	}

	public get dataKeys(){
		return this._dataKeys;
	}

	private constructor(rowData:CSVData){

		const colKeys = rowData.getKeys();

		if(colKeys.length === 0){
			this._errors.push("Input is empty.");
			return;
		}
		
		var mc = requiredColumns.filter(c => !colKeys.includes(c));

		if(mc.length > 0){
			mc.forEach(c => this._errors.push(`Missing column '${c}'`));
			return;
		}

		const markers:any[] = [];

		const dataKeys = (() => {
			const km:Record<string,string[]> = {};
			colKeys
			.map(k => k.match(dataRegex))
			.forEach(m => {

				if(!m || !m.groups){ return; }
				const k = m.groups["key"];
				const sk = m.groups["subkey"];
				if(!Boolean(km[k])){ km[k] = []; }
				km[k].push(sk);
			});
	
			Object.keys(km).forEach(k => km[k] = Array.from(new Set(km[k])));
			
			// make sure required subkeys exist
			const r = Object.keys(km)
			.map(k => {
				
				let mp = false;
				requiredSubkeys.forEach(sk => {
					if(!km[k].includes(sk)){
						this._errors.push(`Expected column missing: 'data_${k}_${sk}'.`);
						mp = true;
					}
				});

				return !mp ? k : null;
			})
			.filter(v => v !== null);
			return r as string[];
		})();

		const processMarkerRow = (row:Record<string,any>, index:number) => {
			const m:any = {};
			const data:any = {};
			dataKeys.forEach(k => {
				const kd:any = {};
				requiredSubkeys.forEach(sk => kd[sk] = row[`data_${k}_${sk}`]);
				const missingSK = Object.keys(kd).find(k => !kd[k] || Number.isNaN(Number(kd[k])));
				if(missingSK){ return; }
				Object.keys(kd).forEach(k => kd[k] = Number(kd[k]))
				data[k] = kd;
			});

			const props = [ "location", "image", "title", "description", ];

			const invalidProps = props.filter(p => {
				const v = validators[p];
				if(!v){ return false; }
				const err = v(row[p]);
				if(err){
					this._errors.push(`Line ${index + 1}, Error validating column '${p}': ${err}`);
				}
				return Boolean(err);
			});

			if(invalidProps.length > 0){
				return;
			}

			props.forEach(k => {
				let v = row[k];
				if(fieldFormatters[k]){
					v = fieldFormatters[k](v);
				}
				m[k] = v;

			});
			m["data"] = data;

			markers.push(m);
		};

		// add each marker row
		rowData.forEachRow(processMarkerRow);

		this._dataKeys = dataKeys;
		this._markers = markers;

	}

	private _errors:string[] = [];
	private _dataKeys:string[] = [];
	private _markers:any[] = [];


}

class CSVData {

	constructor(data:string, config:{
		separator?:string;
	} = {}){

		const separator = config.separator || ",";

		this._lines = (new String(data)).split("\n")
		.map(v => v.trim())
		.filter(l => Boolean(l))
		.map(v => v.split(separator).map(v => v.trim()));

		this._keyIndices = (() => {
			var r:Record<string,number> = {};
			(this._lines[0] || []).forEach((k, i) => r[k] = i);
			return r;
		})();
	}


	public getKeys(){
		return this._lines[0] || [];
	}

	public getRowCount(){
		return this._lines.length - 1;
	}

	public getColumnValue(index:number, key:string){
		var ci = this._keyIndices[key];
		return ci !== undefined ? this._lines[index + 1][ci] : undefined;
	}

	public getRowValues(index:number){
		const d:Record<string,any> = {};
		this._lines[0].forEach(k => d[k] = this.getColumnValue(index, k));
		return d;
	};

	public forEachRow(cb:(row:Record<string,any>, i:number) => void){
		for(var i = 0; i < this._lines.length - 1; i++){
			cb(this.getRowValues(i), i);
		}
	};

	private _lines:string[][] = [];
	private _keyIndices:Record<string,number> = {};


}