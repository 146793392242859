import { GUID } from "../@utils";
import { useMemo, useEffect, useState, createRef } from "react";

export const useDragSelect = <T extends HTMLElement = HTMLElement>(props:{
	onDone?:() => void;
}) => {

	const ref = createRef<T>();
	let el: T | null = null;

	const [ pressed, setPressed ] = useState(false);

	const [ start, setStart ] = useState({ x:0, y:0 });
	const [ end, setEnd ] = useState({ x:0, y:0 });

	const bounds = useMemo(() => {
		const x = Math.min(start.x, end.x);
		const y = Math.min(start.y, end.y);
		const w = Math.abs(start.x - end.x);
		const h = Math.abs(start.y - end.y);
		return {
			x,y,
			w,h,
		};
	}, [ pressed, start, end ]);

	const posFromEvent = (e:MouseEvent) => {
		const lx = (e as any).layerX as number;
		const ly = (e as any).layerY as number;
		const y = ly;
		const x = lx;
		return { x, y };
	};

	const handleMove = (e:MouseEvent) => {
		setEnd(posFromEvent(e));
	};

	const handleDown = (e:MouseEvent) => {
		if(e.button !== 0){ return; }
		e.preventDefault();
		const p = posFromEvent(e);
		setStart(p);
		setEnd(p);
		setPressed(true);
	};

	const handleUp = (e:MouseEvent) => {
		if(!pressed){ return; }
		setPressed(false);
		if(props.onDone){
			props.onDone();
		}
	};

	const cleanup = () => {
		el?.removeEventListener("mouseup", handleUp);
		el?.removeEventListener("mousedown", handleDown);
		el?.removeEventListener("mousemove", handleMove);
	};

	useEffect(() => {
		if(!el){ el = ref.current; }
		el?.addEventListener("mouseup", handleUp, { passive:false });
		el?.addEventListener("mousedown", handleDown, { passive:false });
		el?.addEventListener("mousemove", handleMove, { passive:false });
		return cleanup;
	}, [ ref ])

	return {
		ref,
		active:pressed,
		bounds,
	};
};

export const useKeyPress = (props:{
	key:string;
	onKeyUp?:() => void;
	onKeyDown?:() => void;
}) => {

	const handleUp = (e:KeyboardEvent) => {
		if(props.key !== e.key){ return; }
		e.preventDefault();
		if(props.onKeyUp){
			props.onKeyUp();
		}
	};

	const handleDown = (e:KeyboardEvent) => {
		if(props.key !== e.key){ return; }
		e.preventDefault();
		if(props.onKeyDown){
			props.onKeyDown();
		}
	};

	const cleanup = () => {
		window.removeEventListener("keydown", handleDown);
		window.removeEventListener("keyup", handleUp);
	};

	useEffect(() => {
		window.addEventListener("keydown", handleDown, { passive:false });
		window.addEventListener("keyup", handleUp, { passive:false });
		return cleanup;
	}, []);
};


export const useRefresh = () => {

	const [ refreshKey, setRefreshKey ] = useState(GUID.getGUID());

	const refresh = () => setRefreshKey(GUID.getGUID());

	return {
		refreshKey,
		refresh,
	};
};