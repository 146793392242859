import { createGlobalStyle, css } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "@mdi/font/css/materialdesignicons.min.css";


const leafletOverrides = css`
	.map.grid {
		img.leaflet-tile {
			outline:1px solid rgba(255,255,255,0.5);
		}
	}

	.leaflet-div-icon { border:0; background:transparent; }

	.leaflet-popup-content {
		width:auto !important;
		font-size:1em;
		margin-top:2.2em;
	}

	.leaflet-container {
		font:auto !important;
	}

	.leaflet-popup-close-button {
		margin:0.5em;
		font-size:2em !important;
	}
`;


export const GlobalStyles = createGlobalStyle`

	body {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
			"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
	}

	.hoverable {
		opacity:0.9;
		&:hover { opacity:1; }
	}

	${leafletOverrides};

	.example-track {
		height:15px;
		background:red;
		margin:auto;
	}

	.track-thumb {
	}

	.horizontal-slider {
		height:50px;
		position:relative;
		background:blue;
		display:flex;
	}
	
	.leaflet-popup-content svg {
		z-index:auto;
	}


	.example-thumb {
		width:30px;
		height:30px;
		top:1px;

		cursor:pointer;

		border-radius:50%;
	}

	/* * {
		outline-width: 0 !important;
	} */

`;