import { FC } from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";
import { Box } from "../layout";

export const RangeSlider:FC<{
	value:number[];
	min:number;
	max:number;
	step?:number;
	lazy?:boolean;
	thumbLabels?:boolean;
	onChange?:(v:readonly number[]) => void;
}> = (props) => {

	const {
		step,
		lazy,
	} = {
		...{
			step:1,
		},
		...props,
	};
	
	const sendChange = (v:readonly number[]) => {
		if(props.onChange){
			props.onChange(v);
		}
	};

	const handleAfterChange = (v:number|readonly number[]) => {
		if(!lazy){ return; }
		if(!Array.isArray(v)){ return; }
		sendChange(v);
	};

	const handleChange = (v:number|readonly number[]) => {
		if(lazy){ return; }
		if(!Array.isArray(v)){ return; }
		sendChange(v);
	};

	const ccls = `${!props.thumbLabels ? "notext" : ""}`;

	return (
		<div style={{
			position: "relative"
		}}>
			<StyledSlider
			className=""
			step={ step }
			defaultValue={ props.value }
			renderTrack={ Track }
			renderThumb={ Thumb }
			pearling
			onChange={ handleChange }
			onAfterChange={ handleAfterChange }
			thumbClassName={ `bg-secondary ${ccls}` }
			trackClassName="bg-lightx border border-dark track"
			min={ props.min }
			max={ props.max }
			/>

			<SliderOverlay className="border border-secondarxy"/>

		</div>
	);
};

const SliderOverlay = styled(Box.Abs)`
	pointer-events:none;
	border-radius:10em;
`;

const StyledSlider = styled(ReactSlider)`
	width: 100%;
	height: 25px;
	border-radius:2em;
	background:#ebebeb;
`;

const StyledThumb = styled.div`
	height: 25px;
	width: 25px;
	color: #fff;
	border-radius: 50%;
	cursor: pointer;
	font-size:0.5em;
	outline-width: 0 !important;
	transition:opacity .2s;
	display:flex;
	justify-content:center;
	align-items:center;

	&:hover {
		opacity:1;
	}

	&.notext {
		color:transparent;
	}

`;

const Thumb = (props:any, state:any) => {
	return (
		<StyledThumb {...props}>
			{state.valueNow}
		</StyledThumb>
	);
};

const Track = (props:any, state:any) => {

	const inner = state.index === 1 ? (
		<div className="w-100 bg-light mx-x3 border-top border-bottom"
		style={{

			borderRadius:"10em"
		}}
		>
			x
		</div>
	) : null;

	return (
		<StyledTrack {...props} index={state.index} className="px-1 d-flex">
			{ inner }
		</StyledTrack>
	);
};

const StyledTrack = styled.div<{
	index:number;
}>`
	outline-width: 0 !important;


	top: 0;
	bottom: 0;
	/* background: ${props => props.index === 2 ? '#f00' : props.index === 1 ? '#0f0' : '#ddd'}; */
	/* background: ${props => props.index === 2 ? '#f00' : props.index === 1 ? '#0f0' : '#ddd'}; */
	/* border-radius: 999px; */

	border-radius:10em;

	&:not(.track-1){
		background:transparent !important;
		border:0 !important;
	

	}
`;